@import '../../styles/shared';

$icon-active-color: $color-primary-10;
$icon-inactive-color: #637380;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin: 0 $spacer;
  width: 21px;
  height: 21px;
  fill: $icon-inactive-color;
  line-height: 21px;

  &__active {
    fill: $icon-active-color;
  }
}

.label {
  color: $icon-inactive-color;
  margin-top: 2px;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;

  &__active {
    color: $icon-active-color;
    font-weight: 700;
  }
}
