.wrapper {
  min-height: calc(100vh - (166px + 70px));
  background-color: #fff;
}
.container {
  background-color: #fff;

  h1 {
    margin-top: 1rem;
  }
  &__hero {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 112px;
  }
}

.location {
  margin: 2rem 0 2rem 0;
  text-align: left;
}

.map {
  height: 250px;

  &__container {
    margin: 2rem 0 2rem 0;
    width: 100%;
  }
}
