@charset "UTF-8";

@import 'app';

:root {
  --primary: #00ccf9;
  --secondary: #ffb830;
  --primary-light: #e8faff;
  --primary-dark: #003256;
  --secondary-light: #a13700;
  --gray: #ececec;
  --whitesmoke: #f5f5f5;
  --dark: #222;
}

body {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}
