@import '../../styles/shared';

$text-color: $color-primary-10;

.headline {
  font-weight: 700;
  font-size: 1.2rem;
  color: $text-color;
  text-align: left;
}
