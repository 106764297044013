@import '../../styles/shared';

$font-color: #3c464e;

.label {
  font-weight: 700;
  color: $font-color;
  margin-bottom: 0.8rem;
  text-align: left;
  font-size: 14px;
}
