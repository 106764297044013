@import '../../styles/shared';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal {
  height: auto;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $mediumBreakpoint) {
    border-radius: 8px;
    height: auto;
    max-height: 100vh;
    width: 782px;
  }

  &__main {
    overflow: scroll;
    flex: 1;
    padding: 1rem;

    @media all and (min-width: $mediumBreakpoint) {
      padding: 1.5rem;
    }
  }
}

.fade {
  &--enter {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &--enter-active {
    opacity: 1;
  }

  &--exit {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &--exit-active {
    opacity: 0;
  }
}

.scale {
  &--enter {
    transform: scale(0.6);
    transition: transform 200ms ease-in-out;
  }

  &--enter-active {
    transform: scale(1);
  }

  &--exit {
    transform: scale(1);
    transition: transform 200ms ease-in-out;
  }

  &--exit-active {
    transform: scale(0);
  }
}

.slide {
  &--enter {
    transform: translateY(10%);
    transition: transform 200ms ease-in-out;
  }

  &--enter-active {
    transform: translateY(0);
  }

  &--exit {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
  }

  &--exit-active {
    transform: translateY(10%);
  }
}
