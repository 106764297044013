@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: local('DM Sans'), url('../assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: local('DM Sans'), url('../assets/fonts/DMSans-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: local('DM Sans'), url('../assets/fonts/DMSans-Bold.ttf') format('truetype');
  font-display: swap;
}
