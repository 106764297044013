@import '../../../../styles/shared';

.container {
  background-color: #fff;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li:not(:last-child) {
    margin-bottom: 1rem;
  }
}
