// 1. Global
// ---------

$foundation-palette: (
  primary: #888,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  gray: #cacaca,
);
$body-font-family: 'DM Sans', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue',
  'Arial', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$global-weight-light: 300;
$global-weight-normal: 400;
$global-weight-bold: 600;

// 4. Base Typography
// ------------------

$header-lineheight: 1.2;
$header-styles: (
  small: (
    'h1': (
      'font-size': 26,
    ),
    'h2': (
      'font-size': 20,
    ),
    'h3': (
      'font-size': 16,
    ),
    'h4': (
      'font-size': 15,
    ),
    'h5': (
      'font-size': 14,
    ),
    'h6': (
      'font-size': 13,
    ),
  ),
  medium: (
    'h1': (
      'font-size': 36,
    ),
    'h2': (
      'font-size': 30,
    ),
    'h3': (
      'font-size': 26,
    ),
    'h4': (
      'font-size': 20,
    ),
    'h5': (
      'font-size': 16,
    ),
    'h6': (
      'font-size': 15,
    ),
  ),
);
$blockquote-color: $white;
$cite-color: $white;
