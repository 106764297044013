@import '../../styles/shared';

$imageWidth: 64px;
$placeholder-color: #aebcc7;

.container {
  display: flex;
  align-items: center;
}

.image {
  flex: 0 0 $imageWidth;
  align-self: flex-start;
  order: 1;
  margin: 0 10px 0 0;
  height: $imageWidth;
  width: $imageWidth;
  object-fit: contain;
  border-radius: 10px;
}

.placeholder {
  fill: $placeholder-color;
  background-color: #f3f5f7;
  border-radius: 10px;
}
