@import '../../styles/shared';

$action-background-color: $color-secondary;
$action-text-color: $color-primary-10;
$text-color: $color-primary-10;

.button {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0.8rem;
  width: 100%;
  min-height: 46px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $text-color;

  @media all and (min-width: $mediumBreakpoint) {
    width: auto;
    min-width: 150px;
  }

  &:disabled {
    background: #ffe1b3;
    color: white;
  }
}

.spinner {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: $text-color;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.action {
  padding: 0.8rem;
  color: $action-text-color;
  background-color: $action-background-color;
}

.cancel {
  // margin-top: 0.8rem;
  padding: 0.8rem;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
