@import '../../../../styles/shared';

.container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  // flex-wrap: wrap;
  // flex-direction: row;
  // align-items: flex-start;
  // justify-content: space-between;

  &__info_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__price_wrapper {
    // background: red;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__image {
    flex: 0 0 80px;
    align-self: flex-end;
    order: 0;
    margin-left: 10px;
    width: 80px;
    // object-fit: contain;
  }

  &__description {
    // background: red;
    // order: 0;
    flex: 3 1;
    // flex-basis: calc(100% - 90px);
    // align-self: auto;
    align-self: center;
    margin: 0;
    overflow: hidden;
  }

  &__title {
    // background: red;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  &__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6rem;
    font-weight: 300;
    margin: 0;
    text-align: left;
  }

  &__quantity {
    order: 0;
    flex: 0 0;
    flex-basis: 160px;
    align-self: center;
    margin: 6px 6px 0 0;
  }

  &__price {
    // background-color: red;
    // order: 0;
    // flex: 0 0;
    // flex-basis: auto;
    flex-grow: 1;
    align-self: center;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 18px;
    text-align: left;
    color: $color-secondary;

    small {
      font-size: 0.6rem;
      display: block;
    }
  }

  &__total {
    order: 0;
    flex: 0 0;
    flex-basis: auto;
    align-self: center;
    white-space: nowrap;
    font-size: 0.8rem;
    margin-right: 18px;
    text-align: right;

    small {
      font-size: 0.6rem;
      display: block;
    }
  }

  &__cart {
    flex: 0 0 80px;
    align-self: flex-end;
    cursor: pointer;
    border: 1px solid #dbd6c7;
    padding: 0;
    border-radius: 2px 0 0 2px;
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJ0LXBsdXMiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jYXJ0LXBsdXMgZmEtdy0xOCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik01MDQuNzE3IDMyMEgyMTEuNTcybDYuNTQ1IDMyaDI2OC40MThjMTUuNDAxIDAgMjYuODE2IDE0LjMwMSAyMy40MDMgMjkuMzE5bC01LjUxNyAyNC4yNzZDNTIzLjExMiA0MTQuNjY4IDUzNiA0MzMuODI4IDUzNiA0NTZjMCAzMS4yMDItMjUuNTE5IDU2LjQ0NC01Ni44MjQgNTUuOTk0LTI5LjgyMy0uNDI5LTU0LjM1LTI0LjYzMS01NS4xNTUtNTQuNDQ3LS40NC0xNi4yODcgNi4wODUtMzEuMDQ5IDE2LjgwMy00MS41NDhIMjMxLjE3NkMyNDEuNTUzIDQyNi4xNjUgMjQ4IDQ0MC4zMjYgMjQ4IDQ1NmMwIDMxLjgxMy0yNi41MjggNTcuNDMxLTU4LjY3IDU1LjkzOC0yOC41NC0xLjMyNS01MS43NTEtMjQuMzg1LTUzLjI1MS01Mi45MTctMS4xNTgtMjIuMDM0IDEwLjQzNi00MS40NTUgMjguMDUxLTUxLjU4Nkw5My44ODMgNjRIMjRDMTAuNzQ1IDY0IDAgNTMuMjU1IDAgNDBWMjRDMCAxMC43NDUgMTAuNzQ1IDAgMjQgMGgxMDIuNTI5YzExLjQwMSAwIDIxLjIyOCA4LjAyMSAyMy41MTMgMTkuMTlMMTU5LjIwOCA2NEg1NTEuOTljMTUuNDAxIDAgMjYuODE2IDE0LjMwMSAyMy40MDMgMjkuMzE5bC00Ny4yNzMgMjA4QzUyNS42MzcgMzEyLjI0NiA1MTUuOTIzIDMyMCA1MDQuNzE3IDMyMHpNNDA4IDE2OGgtNDh2LTQwYzAtOC44MzctNy4xNjMtMTYtMTYtMTZoLTE2Yy04LjgzNyAwLTE2IDcuMTYzLTE2IDE2djQwaC00OGMtOC44MzcgMC0xNiA3LjE2My0xNiAxNnYxNmMwIDguODM3IDcuMTYzIDE2IDE2IDE2aDQ4djQwYzAgOC44MzcgNy4xNjMgMTYgMTYgMTZoMTZjOC44MzcgMCAxNi03LjE2MyAxNi0xNnYtNDBoNDhjOC44MzcgMCAxNi03LjE2MyAxNi0xNnYtMTZjMC04LjgzNy03LjE2My0xNi0xNi0xNnoiPjwvcGF0aD48L3N2Zz4=')
      no-repeat 50%;
    background-size: 26px 26px;
    width: 80px;
    height: 49px;
    background-position: center;

    &:hover {
      background-color: #eee;
    }
  }
}

.animation {
  animation: animation-keyframes 150ms;
}
@keyframes animation-keyframes {
  0% {
    transform: scale3d(0.8, 0.8, 1);
  }
  25%,
  50% {
    // opacity: 1;
    transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    // opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }
}
