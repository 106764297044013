@import 'variables';

$spacer: 24px;

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer * 0.25) !important;
}

.mt-2 {
  margin-top: ($spacer * 0.5) !important;
}

.mt-3 {
  margin-top: ($spacer * 0.75) !important;
}

.mt-4 {
  margin-top: ($spacer) !important;
}

.mt-5 {
  margin-top: ($spacer * 1.25) !important;
}

.mt-6 {
  margin-top: ($spacer * 1.5) !important;
}

.mt-7 {
  margin-top: ($spacer * 1.75) !important;
}

.mt-8 {
  margin-top: ($spacer * 2) !important;
}
