@import '../../styles/shared';

$block-button-color: $color-secondary;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.avatar {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &__left {
    flex: 0 1;
    height: 75px;
  }

  &__right {
    flex: 3 1;
  }

  &__icon {
    width: 75px;
    height: 75px;
  }
}

.details {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;
  }

  &__name {
    font-size: 0.8rem;
    font-weight: 600;
  }

  &__bio {
    font-size: 0.8rem;
    font-weight: 400;
  }

  &__website {
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.name {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;

    h2 {
      font-size: 1.2rem;
      margin: 0 0 0.4rem 0;
    }
  }

  &__user {
    text-transform: lowercase;
  }

  &__display {
    text-transform: none;
  }

  &__button {
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    color: $block-button-color;
    border: 1px solid $block-button-color;
    border-radius: 4px;
    // height: 1.4rem;
    // text-transform: none;
    padding: 0.2rem;

    &:hover {
      color: $block-button-color;
    }

    &:visited {
      color: $block-button-color;
    }

    @media all and (min-width: $mediumBreakpoint) {
      width: auto;
    }
  }
}
