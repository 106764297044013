@import '../../styles/shared';

$background-color: $color-gray-9;

$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$duration: 0.3s;
// $drawer: drawer;
.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease $duration, height 0s ease $duration,
    transform $duration $ease-in-out-circ;
  > * {
    transition: transform $duration $ease-in-out-circ, opacity $duration $ease-in-out-circ,
      box-shadow $duration $ease-in-out-circ;
  }
  &.drawer-open {
    transition: transform $duration $ease-in-out-circ;
  }
  & &-mask {
    background: #000;
    opacity: 0;
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $duration $ease-in-out-circ, height 0s ease $duration;
  }
  &-content-wrapper {
    position: absolute;
    background: #fff;
  }
  &-content {
    overflow: auto;
    z-index: 1;
    position: relative;
  }
  &-handle {
    position: absolute;
    top: 72px;
    width: 41px;
    height: 34px;
    cursor: pointer;
    z-index: 0;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    &-icon {
      width: 14px;
      height: 2px;
      background: $background-color;
      position: relative;
      transition: background $duration $ease-in-out-circ;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background: $background-color;
        width: 100%;
        height: 2px;
        transition: transform $duration $ease-in-out-circ;
      }
      &:before {
        top: -5px;
      }
      &:after {
        top: 5px;
      }
    }
  }
  &-left,
  &-right {
    width: 0%;
    height: 100%;
    .drawer-content-wrapper,
    .drawer-content {
      height: 100%;
    }
    &.drawer-open {
      width: 100%;
      &.no-mask {
        width: 0%;
      }
    }
  }
  &-left {
    top: 0;
    left: 0;
    .drawer {
      &-handle {
        right: -40px;
        box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 0 4px 4px 0;
      }
    }
    &.drawer-open {
      .drawer {
        &-content-wrapper {
          box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
  &-right {
    top: 0;
    right: 0;
    .drawer {
      &-content-wrapper {
        right: 0;
      }
      &-handle {
        left: -40px;
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px 0 0 4px;
      }
    }
    &.drawer-open {
      & .drawer {
        &-content-wrapper {
          box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
        }
      }
      &.no-mask {
        // https://github.com/ant-design/ant-design/issues/18607
        right: 1px;
        transform: translateX(1px);
      }
    }
  }
  &-top,
  &-bottom {
    width: 100%;
    height: 0%;
    .drawer-content-wrapper,
    .drawer-content {
      width: 100%;
    }
    .drawer-content {
      height: 100%;
    }
    &.drawer-open {
      height: 100%;
      &.no-mask {
        height: 0%;
      }
    }

    .drawer {
      &-handle {
        left: 50%;
        margin-left: -20px;
      }
    }
  }
  &-top {
    top: 0;
    left: 0;
    .drawer {
      &-handle {
        top: auto;
        bottom: -40px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
      }
    }
    &.drawer-open {
      .drawer {
        &-content-wrapper {
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
  &-bottom {
    bottom: 0;
    left: 0;
    .drawer {
      &-content-wrapper {
        bottom: 0;
      }
      &-handle {
        top: -40px;
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px 4px 0 0;
      }
    }
    &.drawer-open {
      .drawer {
        &-content-wrapper {
          box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
        }
      }
      &.no-mask {
        // https://github.com/ant-design/ant-design/issues/18607
        bottom: 1px;
        transform: translateY(1px);
      }
    }
  }
  &.drawer-open {
    .drawer {
      &-mask {
        opacity: 0.3;
        height: 100%;
        transition: opacity 0.3s $ease-in-out-circ;
      }
      &-handle {
        &-icon {
          background: transparent;
          &:before {
            transform: translateY(5px) rotate(45deg);
          }
          &:after {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }
    }
  }
}

// .drawer {
//   .ant-menu-inline,
//   .ant-menu-vertical {
//     border-right: none;
//   }
//   &-content {
//     padding-top: 40px;
//   }
//   &-left {
//     .ant-menu-inline .ant-menu-item:after,
//     .ant-menu-vertical .ant-menu-item:after {
//       left: 0;
//       right: auto;
//     }
//   }
// }

// .drawer__wrapper {
//   .drawer {
//     animation: AlphaTo 0.3s ease-out 0.3s;
//     animation-fill-mode: forwards;
//     opacity: 0;
//   }
// }

// @keyframes AlphaTo {
//   to {
//     opacity: 1;
//     left: 0;
//   }
// }

// .parent-demo {
//   position: relative;
//   overflow: hidden;
//   .drawer {
//     position: absolute;
//   }
// }
