@import '../../styles/shared';

$color: #fff;
$color-primary: $color-secondary;

.container {
  display: flex;
  align-items: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid $color;
  border-right: 2px solid transparent;
  animation: spinner 0.8s linear infinite;
}

.spinner__inverse::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid $color-primary;
  border-right: 2px solid transparent;
  animation: spinner 0.8s linear infinite;
}
