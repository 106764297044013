@import '../../styles/shared';

.footer {
  text-align: center;
  font-size: 1rem;
  padding-top: 1rem;
  padding-bottom: 3rem;
  color: white;
  background-color: $color-primary-10;

  &__language {
    cursor: pointer;
    &_wrapper {
      padding: 1rem 0 1rem 0;
    }
  }

  &__copyright {
    font-size: 0.8rem;

    a:link {
      color: white;
    }

    a:visited {
      color: white;
    }

    a:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.social {
  // &__link {
  //   display: flex;
  //   align-items: center;
  // }

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
    fill: white;

    &__inner {
      fill: white;
    }
  }

  &__label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.list {
  list-style-type: none;
  margin: 2rem 0 0 0;
  padding: 0;

  &--spacing {
    margin: 2rem 0 0 0;
  }

  &__item {
    font-size: 18px;
    color: white;
    margin: 0.25rem 0;

    &__link {
      color: white;

      &:hover {
        color: white;
        text-decoration: none;

        .social__icon {
          fill: white;
        }
      }
    }

    &--headline {
      font-size: 20px;
      margin-bottom: 0.4rem;
    }
  }
}

.navigation {
  &__link {
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0.5rem 0 0.5rem 0;
    display: block;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
