@import '../../styles/shared';

$background-color: white;
$spacing: $spacer * 0.5;

.container {
  display: flex;
  align-items: flex-start;
  background-color: $background-color;
  padding: $spacing;
  min-height: 100vh;
}
