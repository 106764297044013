@import '../../styles/shared';

$font-color: #f05e02;

.container {
  display: flex;
  align-items: center;

  color: $font-color;
  font-size: 13px;
  font-weight: 500;
}
