@import '../../../../styles/shared';

.container {
  color: black;
  background-color: white;

  &__buttons {
    margin-top: 2rem;
  }

  &__total {
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 2 * 18px;
  }

  &__quantity {
    margin-top: 2rem;
    display: flex;
  }
}

.modal {
  &__main {
    flex: 1;
  }

  &__quantity {
    flex-basis: 200px;
    margin: auto;
  }
}

.scrollsafe {
  margin: 90px;
}
