@import '../../styles/shared';

$unchecked-color: #d2dae0;
$checked-color: #f05e02;

.container {
  display: flex;
  align-items: center;
}

.favorite {
  cursor: pointer;
  width: 25px;
  height: 25px;

  &__checked {
    fill: $checked-color;
  }

  &__unchecked {
    fill: $unchecked-color;
  }
}
