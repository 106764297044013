@import '../../styles/shared';

$icon-active-color: $color-primary-10;
$icon-inactive-color: #637380;

.container {
  color: $color-primary-10;
  position: sticky;
  bottom: 0;
  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  background-color: $color-gray-1;
  border-top: 1px solid #aebcc7;
  height: $bottom-navigation-height;

  @media all and (display-mode: standalone) {
    height: $bottom-navigation-height-standalone;
  }
}

.icon {
  margin: 0 $spacer;
  width: 20px;
  // height: 21px;
  fill: $icon-inactive-color;

  &__active {
    cursor: pointer;
    fill: $icon-active-color;
  }
}
