$imageWidth: 250px;
$imageHeight: 250px;
$placeholder-color: #eee;
$font-color: #003256;

.container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: $font-color;

  h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: none;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__image {
    flex: 0 0 $imageWidth;
    order: 1;
    margin: 0 10px 0 0;
    width: $imageWidth;
    height: $imageHeight;
    object-fit: contain;
  }

  &__placeholder {
    fill: $placeholder-color;
  }
}
