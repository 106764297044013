@import '../../styles/shared';

$error-color: #f05e02;
$text-color: #637380;
$placeholder-color: #aebcc7;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input.field {
  height: 2rem;
  box-shadow: none;
  margin: 0;
  border: 1px solid #e7ebee;
  color: $text-color;
  // font-size: 15px;
  font-size: 16px;
  font-weight: 500;

  &::placeholder {
    font-weight: 300;
    color: $placeholder-color;
    font-style: italic;
  }

  &:focus {
    border: 1px solid #1fa0d2;
    // box-shadow: none;
  }

  &__invalid {
    border: 1px solid $error-color;

    &:focus {
      border: 1px solid $error-color;
      // box-shadow: none;
    }
  }
}

.optional {
  color: $placeholder-color;
  font-size: 12px;
  font-weight: 300;
}
