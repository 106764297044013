@import '../../../../styles/shared';

.container {
  color: #000;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  background-color: $color-gray-10;

  &__button {
    margin: 0;
    background-color: $color-secondary;
    color: white;
    font-weight: 600;
    transition: background-color;
    line-height: 1.4rem;
  }
}

.animation {
  animation: animation-keyframes 1s;
}
@keyframes animation-keyframes {
  0% {
    background-color: $color-secondary;
  }
  20% {
    background-color: $color-success;
    box-shadow: 0 0 20px $color-success;
  }
  100% {
    background-color: $color-secondary;
  }
}
