@import '../../styles/shared';

.container {
  display: flex;
  align-items: center;
}

.message {
  font-size: 18px;
  margin-left: 9px;
}
