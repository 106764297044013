@import '../../../../styles/shared';

$plus-icon-color: $color-primary;

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;
  width: 100%;
}

.icons {
  &__plus {
    cursor: pointer;
    width: 16px;
    height: 16px;
    fill: $plus-icon-color;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    list-style: none;
    line-height: 0;

    &:not(:first-child) {
      margin-top: $spacer * 0.5;
    }
  }
}
