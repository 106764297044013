@import '../../../../styles/shared';

$imageWidth: 48px;
$placeholder-color: #aebcc7;
$brand-color: #ec8f05;
$description-color: #003256;

$title-color: #003256;
$title-font-size: 0.8rem;
$title-line-height: 1.2;
$title-lines-to-show: 2;

.container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    order: 0;
    margin: 0;
  }

  &__image {
    flex: 0 0 $imageWidth;
    align-self: flex-start;
    order: 1;
    margin: 0 10px 0 0;
    height: $imageWidth;
    width: $imageWidth;
    object-fit: contain;
    border-radius: 10px;
  }

  &__placeholder {
    fill: $placeholder-color;
    background-color: #f3f5f7;
    border-radius: 10px;
  }

  &__details {
    order: 2;
    flex: 3 1;
    flex-basis: calc(100% - 100px - 10px);
    align-self: center;
    margin: 0;
    overflow: hidden;
  }
}

.title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  // height: $title-font-size * $title-line-height * $title-lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $title-font-size;
  line-height: $title-line-height;
  color: $title-color;
  -webkit-line-clamp: $title-lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  text-align: left;
}

.user {
  font-size: 12px;
  line-height: 17px;

  &__at {
    color: $title-color;
    font-weight: 700;
    line-height: 17px;
  }

  &__username {
    color: #637380;
    font-weight: 400;
    line-height: 17px;
  }
}
