@import '../../styles/shared';

$title-color: $color-gray-9;
$icon-color: $color-gray-9;
$icon-disabled-color: $color-gray-6;

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 8px 0;

  &__text {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: $title-color;
  }
}

.icon {
  flex: 0 1 auto;
  width: 30px;
  height: 30px;

  &__enabled {
    color: $icon-color;
    cursor: pointer;
  }

  &__disabled {
    color: $icon-disabled-color;
    cursor: unset;
  }
}
