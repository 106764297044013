@import '../../styles/shared';

$background-color: #e8faff;
$color: #003256;

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  width: 100%;
  color: $color;
  font-size: 22px;
  font-weight: 600;
  background-color: $background-color;
  border-radius: 0 0 16px 16px;
}

.logo {
  &__wrapper {
    // flex: 0 1 auto;
    text-align: left;
  }

  &__image {
    cursor: pointer;
    height: 34px;
  }
}

.navigation {
  display: flex;
  flex-direction: row;

  &__icon {
    cursor: pointer;
    height: 30px;
    width: 30px;
    margin-left: 1rem;
  }
}
