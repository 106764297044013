@import '../../../../styles/shared';

.container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  // flex-wrap: wrap;
  // flex-direction: row;
  // align-items: flex-start;
  // justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-10;
  }

  &__image {
    flex: 0 0 80px;
    align-self: flex-start;
    order: 0;
    margin: 10px 10px 0 0;
    width: 80px;
    object-fit: contain;
  }

  &__description {
    order: 0;
    flex: 3 1;
    flex-basis: calc(100% - 90px);
    align-self: center;
    margin: 0;
    overflow: hidden;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  &__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6rem;
    font-weight: 300;
    margin: 0;
    text-align: left;
  }

  &__options {
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  &__quantity {
    order: 0;
    flex: 0 0;
    flex-basis: 160px;
    align-self: center;
    margin: 6px 6px 0 0;
  }

  &__price {
    order: 0;
    flex: 0 0;
    flex-basis: auto;
    align-self: center;
    white-space: nowrap;
    font-size: 0.8rem;
    margin-right: 18px;
    text-align: right;

    small {
      font-size: 0.6rem;
      display: block;
    }
  }

  &__total {
    order: 0;
    flex: 0 0;
    flex-basis: auto;
    align-self: center;
    white-space: nowrap;
    font-size: 0.8rem;
    margin-right: 18px;
    text-align: right;

    small {
      font-size: 0.6rem;
      display: block;
    }
  }
}
