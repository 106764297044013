.container {
  margin-top: 1rem;
}

.icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiCiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjAuNzEgMTkuMjkgMTcgMTUuNjFBOSA5IDAgMSAwIDE1LjYxIDE3bDMuNjggMy42OGExLjAwMiAxLjAwMiAwIDAgMCAxLjQyIDAgMSAxIDAgMCAwIDAtMS4zOVpNMTAgMTdhNyA3IDAgMSAxIDAtMTQgNyA3IDAgMCAxIDAgMTRaIiBmaWxsPSIjMDAzMjU2Ii8+Cjwvc3ZnPg==');
  background-position: 0.625rem 0.625rem;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  padding: 0.75rem 1.25rem 0.75rem 2rem;
}

.scan {
  &__icon {
    width: 25px;
    height: 25px;
  }
}
