@import '../../styles/shared';

.wrapper {
  // min-height: calc(100vh - (218px));
  min-height: calc(100vh - ($bottom-navigation-height));
  background-color: #fff;
  padding-bottom: $spacer * 2;

  @media all and (display-mode: standalone) {
    min-height: calc(100vh - ($bottom-navigation-height-standalone));
  }
}

.container {
  background-color: #fff;
}

.footer {
  background-color: #fff;
}
