@import '../../styles/shared';

$modal-main-blue: $color-primary;
$blue: $color-primary-10;
$modal-header-height: 50px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  @media all and (min-width: $mediumBreakpoint) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // @include media-breakpoint-up(lg) {
  //   height: calc(100vh - #{$bar-height-lg});
  // }
}

.modal {
  height: 100vh;
  box-shadow: 20px 20px 60px #888, -20px -20px 60px #fff;
  background-color: white;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $mediumBreakpoint) {
    border-radius: 8px;
    height: auto;
    max-height: 100vh;
    width: 782px;
  }

  &__header {
    background-color: $blue;
    height: $modal-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    color: #fff;
    font-size: 1rem;
    padding: 0 0 0 0.5rem;
    // text-transform: uppercase;

    @media all and (min-width: $mediumBreakpoint) {
      font-size: 1.2rem;
      height: 70px;
      padding: 0 0 0 1.5rem;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__headline {
      display: flex;
      align-items: center;
    }

    &__close {
      cursor: pointer;
      padding: 0.5rem;

      @media all and (min-width: $mediumBreakpoint) {
        padding: 1.5rem;
      }
    }
  }

  &__main {
    // padding: 1rem;
    // height: calc(100% - #{$modal-header-height});
    overflow: scroll;
    flex: 1;

    @media all and (min-width: $mediumBreakpoint) {
      padding: 1.5rem;
    }
  }
}

.icon {
  fill: #fff;
  display: block;

  &__close {
    width: 30px;

    @media all and (min-width: $mediumBreakpoint) {
      width: 30px;
    }
  }
}

.fade {
  &--enter {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &--enter-active {
    opacity: 1;
  }

  &--exit {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &--exit-active {
    opacity: 0;
  }
}

.scale {
  &--enter {
    transform: scale(0.6);
    transition: transform 200ms ease-in-out;
  }

  &--enter-active {
    transform: scale(1);
  }

  &--exit {
    transform: scale(1);
    transition: transform 200ms ease-in-out;
  }

  &--exit-active {
    transform: scale(0);
  }
}

.slide {
  &--enter {
    transform: translateY(10%);
    transition: transform 200ms ease-in-out;
  }

  &--enter-active {
    transform: translateY(0);
  }

  &--exit {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
  }

  &--exit-active {
    transform: translateY(10%);
  }
}
