@import '../../styles/shared';

$font-color: #ec8f05;
$badge-color: $color-primary;

.container {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 0.7rem;
}

.brand {
  font-size: 0.6rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: $font-color;
  text-align: left;
}

.verified {
  &__icon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    color: $badge-color;
    vertical-align: middle;
  }
}
