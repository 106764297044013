@charset "UTF-8";

@import '../settings';

// @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,800');

html,
body {
  -webkit-tap-highlight-color: transparent;
}

.button {
  text-transform: uppercase;
}

h1,
h2 {
  text-transform: uppercase;
  text-align: center;
}

.block {
  &__odd {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__even {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $light-gray;
  }

  &__highlight {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #d3e9f9;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
