@import '../../../../styles/shared';

$font-color: $color-gray-8;

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  height: 100%;
  width: 100%;
}

.footer {
  margin-top: auto;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;

  &__copyright {
    font-size: 0.8rem;

    a:link {
      color: white;
    }

    a:visited {
      color: white;
    }

    a:hover {
      color: white;
      text-decoration: underline;
    }
  }

  &__version {
    font-size: 12px;
    color: $font-color;
  }

  @media all and (display-mode: standalone) {
    margin-bottom: 20px;
  }
}
