@import '../../styles/shared';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  // margin: 64px 0 64px 0;

  @media all and (min-width: $mediumBreakpoint) {
    align-items: baseline;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}
