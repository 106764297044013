@import '../../../../styles/shared';

.container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: left;
  margin: 0.5rem 0 0.5rem 0;
  cursor: pointer;
  // flex-wrap: wrap;
  // flex-direction: row;
  // align-items: flex-start;
  // justify-content: space-between;

  &__info_wrapper {
    flex: 3 1;
    overflow: hidden;
  }

  &__price_wrapper {
    // background: red;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__image {
    flex: 0 0 80px;
    align-self: flex-end;
    order: 0;
    margin-left: 10px;
    width: 80px;
    // object-fit: contain;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -3px;
    text-align: left;
  }

  &__description {
    // background: red;
    order: 0;
    flex: 3 1;
    // flex-basis: calc(100% - 90px);
    // align-self: auto;
    // align-self: flex-start;
    margin: 0;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 300;
  }

  &__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6rem;
    font-weight: 300;
    margin: 0;
    text-align: left;
  }
}

.money {
  &__wrapper {
    flex: 0 0;
    white-space: nowrap;
  }
}

.checkbox {
  margin-right: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &_wrapper {
    box-sizing: border-box;
    user-select: none;
    // margin-right: 1rem;
    position: relative;
    display: flex;
    align-items: center;
  }

  &_checked {
    border: 0.6rem solid $color-primary-10;
    animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);

    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0.2rem;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      transform: rotate(45deg);
      transform-origin: 0% 100%;
      animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
    }
  }
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: white;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.3rem;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.3rem;
    height: 0.7rem;
    border-color: white;
    transform: translate3d(0, -0.55rem, 0) rotate(45deg);
  }
}
