@import '../../../../styles/shared';

$background-color: white;
$color: black;

.container {
  color: $color;
  background-color: $background-color;
}

.divider {
  border-color: #e7ebee;
}
