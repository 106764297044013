.container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  // flex-wrap: nowrap;
  // justify-content: center;
  // align-content: stretch;
  // align-items: flex-start;

  &__quantity {
    text-align: center;
  }

  &__plus {
    cursor: pointer;
    border: 1px solid #dbd6c7;
    padding: 0;
    border-radius: 2px 0 0 2px;
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwbHVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGx1cyBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxNiAyMDhIMjcyVjY0YzAtMTcuNjctMTQuMzMtMzItMzItMzJoLTMyYy0xNy42NyAwLTMyIDE0LjMzLTMyIDMydjE0NEgzMmMtMTcuNjcgMC0zMiAxNC4zMy0zMiAzMnYzMmMwIDE3LjY3IDE0LjMzIDMyIDMyIDMyaDE0NHYxNDRjMCAxNy42NyAxNC4zMyAzMiAzMiAzMmgzMmMxNy42NyAwIDMyLTE0LjMzIDMyLTMyVjMwNGgxNDRjMTcuNjcgMCAzMi0xNC4zMyAzMi0zMnYtMzJjMC0xNy42Ny0xNC4zMy0zMi0zMi0zMnoiPjwvcGF0aD48L3N2Zz4=')
      no-repeat 50%;
    background-size: 26px 26px;
    width: 49px;
    height: 49px;
    background-position: center;
    // transition: background 0.8s;
    flex-shrink: 0;

    &:hover {
      // background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
      background-color: #eee;
    }

    // &:active {
    //   background-color: #ccc;
    //   background-size: 50%;
    //   transition: background 0s;
    // }
  }

  &__minus {
    cursor: pointer;
    border: 1px solid #dbd6c7;
    padding: 0;
    border-radius: 2px 0 0 2px;
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJtaW51cyIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLW1pbnVzIGZhLXctMTQiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNNDE2IDIwOEgzMmMtMTcuNjcgMC0zMiAxNC4zMy0zMiAzMnYzMmMwIDE3LjY3IDE0LjMzIDMyIDMyIDMyaDM4NGMxNy42NyAwIDMyLTE0LjMzIDMyLTMydi0zMmMwLTE3LjY3LTE0LjMzLTMyLTMyLTMyeiI+PC9wYXRoPjwvc3ZnPg==')
      no-repeat 50%;
    background-size: 26px 26px;
    width: 49px;
    height: 49px;
    background-position: center;
    // transition: background 0.8s;
    flex-shrink: 0;

    &:hover {
      // background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
      background-color: #eee;
    }

    // &:active {
    //   background-color: #ccc;
    //   background-size: 50%;
    //   transition: background 0s;
    // }
  }

  &__remove {
    cursor: pointer;
    border: 1px solid #dbd6c7;
    padding: 0;
    border-radius: 2px 0 0 2px;
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ0cmFzaC1hbHQiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS10cmFzaC1hbHQgZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0zMiA0NjRhNDggNDggMCAwIDAgNDggNDhoMjg4YTQ4IDQ4IDAgMCAwIDQ4LTQ4VjEyOEgzMnptMjcyLTI1NmExNiAxNiAwIDAgMSAzMiAwdjIyNGExNiAxNiAwIDAgMS0zMiAwem0tOTYgMGExNiAxNiAwIDAgMSAzMiAwdjIyNGExNiAxNiAwIDAgMS0zMiAwem0tOTYgMGExNiAxNiAwIDAgMSAzMiAwdjIyNGExNiAxNiAwIDAgMS0zMiAwek00MzIgMzJIMzEybC05LjQtMTguN0EyNCAyNCAwIDAgMCAyODEuMSAwSDE2Ni44YTIzLjcyIDIzLjcyIDAgMCAwLTIxLjQgMTMuM0wxMzYgMzJIMTZBMTYgMTYgMCAwIDAgMCA0OHYzMmExNiAxNiAwIDAgMCAxNiAxNmg0MTZhMTYgMTYgMCAwIDAgMTYtMTZWNDhhMTYgMTYgMCAwIDAtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+')
      no-repeat 50%;
    background-size: 26px 26px;
    width: 49px;
    height: 49px;
    background-position: center;
    // transition: background 0.8s;
    flex-shrink: 0;

    &:hover {
      // background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
      background-color: #eee;
    }

    // &:active {
    //   background-color: #ccc;
    //   background-size: 50%;
    //   transition: background 0s;
    // }
  }

  input[type='text'] {
    display: block;
    box-sizing: border-box;
    height: 2.55208rem;
    margin: 0;
    padding: 0;
    border: 1px solid #cacaca;
    border-radius: 6px;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    font-family: inherit;
    font-size: 0.83333rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0a0a0a;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
