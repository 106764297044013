@import '../../styles/shared';

$imageWidth: 60px;
$placeholder-color: $color-gray-5;
$title-color: $color-gray-9;

.item {
  // position: relative;
  display: flex;
  flex-direction: row;
  // margin: 18px 0;
  background-color: white;
  text-align: left;

  .text {
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    padding: 9px 0 9px 0;
    color: $title-color;
    // flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      // box-shadow: 0 1px rgba(255, 255, 255, 0.6);
      margin-top: -0.7em;
      background: #c8d4d9;
      transform-origin: center right;
      // animation: strikethrough 1s 0.5s cubic-bezier(0.55, 0, 0.1, 1) 1;
      transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
      transform: scaleX(0);
    }

    &__checked {
      color: #c8d4d9;
      display: inline-block;
      // text-decoration: line-through;

      &::after {
        transform: scaleX(1);
        transform-origin: center left;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      flex: 3 1;
      cursor: pointer;
      overflow: hidden;
    }
  }

  .button {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    float: left;
    width: 1rem;
    height: 1rem;
    top: 0.45rem;
    left: 0.35rem;
    border: 1px solid #497081;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.4s ease-out 0.3s;
    padding: 9px 0 9px 0;

    &__checked {
      transition: all 0.3s ease-in;
      transform: scale(1.5);
      opacity: 0;
    }

    &__wrapper {
      cursor: pointer;
      width: 2rem;
      padding: 3px;
    }
  }

  .checkmark {
    position: relative;
    top: -0.5rem;
    left: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    stroke: #c8d4d9;
    fill: none;
    stroke-width: 1.5;
    stroke-dasharray: 30 30;
    stroke-dashoffset: 30;
    transform: scale(1.3, 1.5);
    transition: all 0.3s ease-out;

    &__checked {
      stroke-dashoffset: 0;
      transition: all 0.4s ease-out 0.3s;
    }

    &:checked ~ .button {
      transition: all 0.3s ease-in;
      transform: scale(1.5);
      opacity: 0;
    }
  }

  &__image {
    // flex: 0 0 $imageWidth;
    // align-self: flex-start;
    // order: 1;
    margin: 0;
    height: $imageWidth;
    width: $imageWidth;
    // object-fit: contain;
  }

  &__placeholder {
    fill: $placeholder-color;
  }

  // input {
  //   display: none;

  //   &:checked ~ .text {
  //     color: #c8d4d9;
  //   }

  //   &:checked ~ .button {
  //     transition: all 0.3s ease-in;
  //     transform: scale(1.5);
  //     opacity: 0;
  //   }

  //   &:checked ~ .checkmark {
  //     stroke-dashoffset: 0;
  //     transition: all 0.4s ease-out 0.3s;
  //   }
  // }
}

// .strikethrough {
//   display: inline-block;
//   position: relative;
//   transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
// }

// .strikethrough:after {
//   content: '';
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 2px;
//   box-shadow: 0 1px rgba(255, 255, 255, 0.6);
//   margin-top: -0.7em;
//   background: black;
//   transform-origin: center left;
//   animation: strikethrough 1s 0.5s cubic-bezier(0.55, 0, 0.1, 1) 1;
//   transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
// }

// .strikethrough:hover {
//   color: rgba(200, 0, 0, 1);
//   background: rgba(255, 189, 182, 0.3);
// }

// .strikethrough:hover:after {
//   transform: scaleX(0);
//   transform-origin: center right;
// }
