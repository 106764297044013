@charset "UTF-8";

.container {
  display: block;
  min-height: calc(100vh - (95px));
}

.list {
  margin: 10px;

  &__add {
    padding: 16px 8px 16px 8px;
    background-color: white;
    color: black;
    cursor: pointer;
  }

  &__item {
    padding: 16px 8px 16px 8px;
    background-color: white;
    color: black;
    cursor: pointer;
  }

  &__header {
    padding: 0.5rem;
    background-color: white;
    color: black;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
