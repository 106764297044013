@import '../../styles/shared';

.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  &__left {
    align-self: start;
  }

  &__right {
    align-self: end;
  }
}
