@import '../../styles/shared';

$text-color: #8399a9;

.container {
  display: flex;
  align-items: center;
  // margin-top: 0.8rem;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
  line-height: 1rem;
  color: $text-color;
}
