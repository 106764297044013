@import '../../styles/shared';

$action-background-color: $color-secondary;
$action-text-color: $color-primary-10;
$text-color: $color-primary-10;

.button {
  cursor: pointer;
  display: inline-block;
  padding: 0.8rem;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
  color: $text-color;

  @media all and (min-width: $mediumBreakpoint) {
    width: auto;
    min-width: 150px;
  }

  &:disabled {
    background: #ffe1b3;
    color: white;
  }
}

.action {
  padding: 0.8rem;
  color: $action-text-color;
  background-color: $action-background-color;
}

.cancel {
  // margin-top: 0.8rem;
  padding: 0.8rem;
}
