@import '../../styles/shared';

$link-color: #000;
$logo-color: #637380;
$info-color: $color-gray;

.wrapper {
  min-height: calc(100vh - (166px + 70px));
  background-color: #fff;
}
.container {
  background-color: #fff;

  h1 {
    margin-top: 1rem;
  }
}

.home {
  &__home {
    &--message {
      margin: 1rem 0 1rem 0;
      font-size: 1.2rem;
    }
    &--claim {
      margin: 1rem 0 1rem 0;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  &__share {
    &--delivery-mode {
      label {
        color: white;
      }
    }
  }
}

.navigation {
  &__link {
    color: $link-color;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0.5rem 0 0.5rem 0;
    display: block;

    &:hover {
      color: $link-color;
      text-decoration: underline;
    }
  }
}

.spacer {
  &__top {
    margin-top: 1rem;
  }
}

.headline {
  font-size: 1.5rem;
  font-weight: 600;
}

.board {
  &__selected {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}

.logo {
  &__wrapper {
    // flex: 0 1 auto;
    text-align: left;
  }

  &__image {
    fill: $logo-color;
    height: 34px;
  }
}

.info {
  &__id {
    font-size: 0.8rem;
    color: $info-color;
  }
}
