@import '../../styles/shared';

$font-color: white;
$background-color: $color-primary-10;
$hover-color: #ec8f05;

.container {
  display: inline-block;
  cursor: pointer;
}

.menu {
  background-color: $background-color;
  border-radius: 8px;
  padding: 0.5rem 0;
  position: absolute;
  width: 200px;
  bottom: 51px;
  left: -6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: $font-color;

      &:hover {
        background-color: $hover-color;
      }
    }
  }

  &__arrow {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $background-color;
    position: absolute;
    bottom: -15px;
    left: 10px;
  }

  &__label {
    font-size: 14px;
    margin-left: 1rem;
  }
}

.flag {
  position: relative;
  display: inline-block;

  &__image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid $background-color;
    cursor: pointer;
  }
}

.language {
  &__label {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
  }
}
