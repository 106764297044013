@import '../../../../styles/shared';

$title-color: $color-gray-9;
$icon-color: $color-gray-7;
$badge-color: rgb(33, 128, 232);

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  color: $title-color;
  font-size: 0.9rem;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  margin: 0.5rem 0;
  text-align: left;
}

.title {
  font-weight: 600;
  color: $title-color;
  // flex-grow: 1;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: inline-block;
  // position: relative;
}

.description {
  font-size: 0.8rem;
  color: $title-color;
  // flex-grow: 1;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: inline-block;
  // position: relative;
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  color: $title-color;

  &__icon {
    width: 16px;
    height: 16px;
    color: $icon-color;
  }

  &__label {
    margin-left: 4px;
  }
}

.verified {
  &__icon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    color: $badge-color;
  }
}

.spacer {
  &__left {
    margin-left: 16px;
  }
}
