// $primary: #37cfff;
// $secondary: #f4b42c;
// $success: #71e40b;
// $warning: #ffae00;
// $alert: #e40b42;
// $gray: #cacaca;

// $gray-10: lighten(#000, 90%);
// $gray-90: lighten(#000, 90%);

// $gray-01: #ffffff;
// $gray-02: #fafafa;
// $gray-03: #f5f5f5;
// $gray-04: #f0f0f0;
// $gray-05: #d9d9d9;
// $gray-06: #bfbfbf;
// $gray-07: #8c8c8c;
// $gray-08: #595959;
// $gray-09: #434343;
// $gray-10: #262626;
// $gray-11: #1f1f1f;
// $gray-12: #141414;
// $gray-13: #000000;

// $primary-dark: #168585;
// $primary-darker: darken(#3b688f, 20%);

// $primary-light: lighten(#3b688f, 2%);

// imported for Modal from cazzzino-test-project
// should be consolidated into Ordist breakpoints
// $mediumBreakpoint: 1150px;
// $largeBreakpoint: 1350px;

$smallBreakpoint: 0;
$mediumBreakpoint: 640px;
$largeBreakpoint: 1024px;
$xlargeBreakpoint: 1200px;
$xxlargeBreakpoint: 1440px;

$bottom-navigation-height: 61px;
$bottom-navigation-height-standalone: 81px;

/* Add colors, sizes etc. */

$color-primary: #00ccf9;
$color-primary-1: #e6ffff;
$color-primary-2: #a3faff;
$color-primary-3: #7af4ff;
$color-primary-4: #52ebff;
$color-primary-5: #29dfff;
$color-primary-6: $color-primary;
$color-primary-7: #00a6d4;
$color-primary-8: #0082ad;
$color-primary-9: #006187;
$color-primary-10: #003256;

$color-secondary: #ffb830;
$color-secondary-1: #fffcf0;
$color-secondary-2: #fff6d4;
$color-secondary-3: #ffeaab;
$color-secondary-4: #ffdc82;
$color-secondary-5: #ffcb59;
$color-secondary-6: $color-secondary;
$color-secondary-7: #d9911e;
$color-secondary-8: #b36f10;
$color-secondary-9: #8c5006;
$color-secondary-10: #663603;

$color-success: #3adb76;
$color-success-1: #f0fff3;
$color-success-2: #e6ffeb;
$color-success-3: #bdffce;
$color-success-4: #8ef5ad;
$color-success-5: #61e88e;
$color-success-6: $color-success;
$color-success-7: #26b55f;
$color-success-8: #178f4b;
$color-success-9: #0c6937;
$color-success-10: #074224;

$color-warning: #ffae00;
$color-warning-1: #fffbe6;
$color-warning-2: #ffeea3;
$color-warning-3: #ffe27a;
$color-warning-4: #ffd452;
$color-warning-5: #ffc229;
$color-warning-6: $color-warning;
$color-warning-7: #d98d00;
$color-warning-8: #b36e00;
$color-warning-9: #8c5200;
$color-warning-10: #663800;

$color-alert: #cc4b37;
$color-alert-1: #fff4f0;
$color-alert-2: #ffeee8;
$color-alert-3: #f2c4b6;
$color-alert-4: #e69a87;
$color-alert-5: #d9725d;
$color-alert-6: $color-alert;
$color-alert-7: #a63124;
$color-alert-8: #801d16;
$color-alert-9: #590d0b;
$color-alert-10: #330606;

$color-gray: #bfbfbf;
$color-gray-1: #ffffff;
$color-gray-2: #fafafa;
$color-gray-3: #f5f5f5;
$color-gray-4: #f0f0f0;
$color-gray-5: #d9d9d9;
$color-gray-6: $color-gray;
$color-gray-7: #8c8c8c;
$color-gray-8: #595959;
$color-gray-9: #434343;
$color-gray-10: #262626;
$color-gray-11: #1f1f1f;
$color-gray-12: #141414;
$color-gray-13: #000000;

$white: #ffffff;
