@import '../../styles/shared';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  text-align: center;

  border: 1px solid #ffece1;
  border-radius: 16px;

  padding: 32px 16px 16px 16px;

  width: 100%;

  background-color: white;
}

.icon {
  color: #f05e02;
  height: 40px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 100%;

  &__delete {
    background-color: #f05e02;
    color: white;

    &:disabled {
      cursor: default;
      background: #f05e02;
      color: white;
    }
  }

  &__cancel {
    margin-left: 12px;
    background-color: #f3f5f7;

    &:disabled {
      cursor: default;
      background: #f3f5f7;
      color: white;
    }
  }
}
