@import '../../styles/shared';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  @media all and (min-width: $mediumBreakpoint) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: 0;
  }

  &__video {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    video {
      /* Make video to at least 100% wide and tall */
      min-width: 100%;
      min-height: 100%;

      /* Setting width & height to auto prevents the browser from stretching or squishing the video */
      width: auto;
      height: auto;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.barcode {
  &__canvas {
    display: none;
  }
}

.picker {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: auto;
  // max-width: 1280px;
  // max-height: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $mediumBreakpoint) {
    border-radius: 8px;
    height: auto;
    max-height: 100vh;
    width: 782px;
  }
}
