@charset "UTF-8";

.container {
  color: black;
  background-color: white;

  &__total {
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 2 * 18px;
  }
}
