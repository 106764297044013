@import '../../styles/shared';

$font-color: $color-primary-10;

.App {
  text-align: left;
}

.App-header {
  background-color: #e8faff;
  min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  padding-top: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f5f5f5;
}

.App-block {
  background: #fff;
  padding: 18px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pwa__update--wrapper {
  padding: 0.5rem;
  text-align: center;
  background-color: darken(#e8faff, 10%);
  color: $font-color;
  font-size: 0.8rem;

  button {
    color: white;
    vertical-align: baseline;
    margin: 0;
    background-color: red;
    font-weight: bold;
  }
}
