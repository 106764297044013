@import '../../styles/shared';

.container {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff, 0 0 0 2px #999, 0 1px 3px 2px rgba(0, 0, 0, 0.2);
}
