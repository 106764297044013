@import '../../../../styles/shared';

.container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // width: 100vw;
  // height: 100vh;
  // margin-top: 12px;
  // background-color: #fff;

  // position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // grid-template-columns: 20px auto 100px;
  // grid-gap: 20px;
  // width: 320px;
  // height: 62px;
  padding: 1rem;
  // border: solid 2px #000;
  border-radius: 6px;
  // border: 2px solid transparent;
  border: 2px solid $color-gray-4;
  background-color: transparent;
  transition: all 300ms ease-in;
  cursor: pointer;

  &:hover {
    border-color: $color-primary;
  }

  &__selected {
    color: white;
    background-color: $color-primary;
  }
}

.container__address {
  flex: 1;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1rem * 1.5;
}

.container__contact {
  flex: 1;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1rem * 1.5;
}

.title {
  flex: 1;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
}

.message {
  flex: 1;
  color: $color-warning;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 0.8rem * 1.5;
}

.description {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
}
